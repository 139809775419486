var $class="se2--header-se--metabar-partials-and-links-fro-mobile-menu",$name="HeaderSE/metabarPartialsAndLinksFroMobileMenu",$path="app/components/HeaderSE/partials/metabarPartialsAndLinksFroMobileMenu/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';

const linkToMySchneiderClassName = '.se2--header-se--metabar-link.login-link';
const linkToMySchneiderSpanClassName = '.se2--header-se--metabar-link.login-link .se2-icon-new-tab';

export default shell.registerPartial($this, ({ mount }) => {
  mount((element) => {
    // Temporary replacing regular JSON value text, for 'mobileLabelMySchneider' value in the mobile view only
    const updateTextForMobileLabelMySchneider = () => {
      const dataLabelMySchneider = element.getAttribute('data-label-myschneider');
      const linkToMySchneiderElement = element.querySelector(linkToMySchneiderClassName);
      const linkToMySchneiderElementSpan = element.querySelector(linkToMySchneiderSpanClassName);
      const linkToMySchneiderElementSpanInnerText = linkToMySchneiderElementSpan?.innerText;

      if (linkToMySchneiderElement && linkToMySchneiderElementSpan) {
        if (!dataLabelMySchneider) {
          return;
        }
        linkToMySchneiderElement.innerHTML = linkToMySchneiderElement
          .innerHTML.replace(linkToMySchneiderElementSpanInnerText,
            dataLabelMySchneider);
      }
    };
    updateTextForMobileLabelMySchneider(element);
  });
});
